import globalStore from '@/services/global.state';
import {goTo} from './route';

export function needLoginBeforeDownload() {
  return (
    !globalStore.token &&
    (new URL(location.href).searchParams.get('pixelid') != null ||
      localStorage.getItem('pixelid') != null ||
      localStorage.getItem('promotionChannelId'))
  );
}

export function stopBeforeDownload() {
  if (needLoginBeforeDownload()) {
    goTo('Login', {backPage: 'Home'});
    return true;
  }
  return false;
}

export function formatNumberNoRound(value: number, decimals: number) {
  const factor = Math.pow(10, decimals);
  return Math.floor(value * factor) / factor;
}
