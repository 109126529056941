import Text from '@/components/basic/text';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import theme from '@/style';
import LazyImage from '@/components/basic/image';
import {goTo} from '@/utils';
import React, {useEffect, useState} from 'react';
import {View} from 'react-native';
import {useInnerStyle} from '../home.hooks';
import globalStore from '@/services/global.state';
import {notYetWarning} from '@/pages/me/me.variable';
import {useTranslation} from 'react-i18next';
import {getHomeGoldGames, getGameURL, getSportGames} from '../home.service';
import {HomeGoldGame, SportItem} from '../home.type';
import {toSports} from '@/utils';
import SportModal from '@/components/business/sport-modal';
import {debounce} from '@/utils';

type SportsModalProps = {
  popVisible: boolean;
  setPopVisible: React.Dispatch<React.SetStateAction<boolean>>;
  list: SportItem[];
  getSportURL: Function;
};
interface HomeGoldAreaProps {
  liveCategoryId: number;
  casinoCategoryId: number;
}

const HomeGoldArea: React.FC<HomeGoldAreaProps> = ({
  liveCategoryId,
  casinoCategoryId,
}) => {
  const {i18n} = useTranslation();
  const {
    size: {screenWidth},
    goldStyles,
  } = useInnerStyle();
  const [list, setList] = useState<HomeGoldGame[]>([]);
  const [sports, setSports] = useState<SportItem[]>([]);
  const [popVisible, setPopVisible] = React.useState(false);
  const getHomeGoldList = () => {
    getHomeGoldGames().then(setList);
  };
  const getSports = () =>
    getSportGames()
      .then(res => {
        if (!res || !res.length) {
          globalStore.globalTotal.next(notYetWarning);
          return;
        }
        if (res.length > 1) {
          setSports(res);
          setPopVisible(true);
        } else {
          getSportURL(res[0].moduleType);
        }
      })
      .catch(e => {
        const {log} = console;
        log('error', e);
      });
  const getSportURL = debounce((gametype: string) => {
    getGameURL(gametype)
      .then((url: string) => {
        setPopVisible(false);
        toSports(gametype.split('_')[0].toUpperCase() + ' Sports', url);
      })
      .catch(e => {
        const {log} = console;
        log('error', e);
      });
  });
  useEffect(() => {
    getHomeGoldList();
  }, []);
  const jump = (v: HomeGoldGame) => {
    switch (v.moduleName) {
      case 'Scratch Off': {
        goTo('Scratch', {hideInnerTitle: '1'});
        break;
      }
      case 'Live': {
        goTo('LiveCasino', {secondPage: '1', categoryId: liveCategoryId});
        break;
      }
      case 'Games': {
        goTo('Casino', {categoryId: casinoCategoryId});
        break;
      }
      case 'Cricket': {
        if (globalStore.token) {
          getSports();
        } else {
          goTo('Login');
        }
        break;
      }
      case 'Lottery': {
        goTo('Lottery');
        break;
      }
      default: {
        globalStore.globalTotal.next(notYetWarning);
      }
    }
  };
  const DISIGN_WEIDTH = 375;
  const computedSize = (num: number) => (num * screenWidth) / DISIGN_WEIDTH;
  const size = {
    computedL: computedSize(theme.paddingSize.l),
    computedS: computedSize(theme.paddingSize.s),
    computed60: computedSize(60),
  };
  return (
    <View
      style={[
        theme.flex.row,
        theme.flex.between,
        theme.flex.centerByCol,
        {
          marginHorizontal: size.computedL,
          marginTop: size.computedS,
          marginBottom: size.computedL,
        },
      ]}>
      {list.map((v, i) => {
        return (
          <NativeTouchableOpacity
            key={i}
            style={[theme.flex.col, theme.flex.center]}
            onPress={() => {
              jump(v);
            }}>
            <LazyImage
              imageUrl={v.bgImgUrl}
              width={size.computed60}
              height={size.computed60}
              occupancy="transparent"
            />
            <Text
              fontFamily="fontDin"
              fontWeight="700"
              color={theme.fontColor.main}
              textAlign="center"
              style={[goldStyles.title, theme.fill.fillW]}>
              {i18n.t(v.moduleName)}
            </Text>
          </NativeTouchableOpacity>
        );
      })}
      <SportModal<SportsModalProps>
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        list={sports}
        getSportURL={getSportURL}
      />
    </View>
  );
};

export default HomeGoldArea;
