import Text from '@/components/basic/text';
import theme from '@/style';
import React from 'react';
import {Image, ImageRequireSource, View} from 'react-native';
import {BasicObject, NavigatorScreenProps} from '@/types';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import Home from './pages/home';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {Shadow} from 'react-native-shadow-2';
import Me from './pages/me';
import Promotion from '@/common-pages/promotion';
import Invitation from '@/common-pages/invitation';
import {toAgentApply} from '@/utils';
import ProxyHome from '@/common-pages/proxy';
import AgentIcon from './pages/svgs/agent';

const Tab = createBottomTabNavigator();

const homeIcon = require('@assets/icons/main-tab/home.webp');
const homeActiveIcon = require('@assets/icons/main-tab/home-active.webp');
const promotionsIcon = require('@assets/icons/main-tab/promotions.webp');
const promotionsActiveIcon = require('@assets/icons/main-tab/promotions-active.webp');
const inviteIcon = require('@assets/icons/main-tab/invite.webp');
const meIcon = require('@assets/icons/main-tab/me.webp');
const meActiveIcon = require('@assets/icons/main-tab/me-active.webp');

const shadow = {
  startColor: '#0004',
  distance: 1,
  offset: [0, 0],
  style: [
    theme.flex.row,
    theme.flex.around,
    theme.background.white,
    theme.fill.fillW,
    {
      height: 50,
    },
  ] as BasicObject[],
} as BasicObject;

if (globalStore.isWeb) {
  shadow.distance = 0;
  delete shadow.offset;
  shadow.style.push({
    boxShadow: '0 0 1px 0px #0004',
  });
}

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img?: ImageRequireSource;
  activeImg?: ImageRequireSource;
  unmountOnBlur?: boolean;
  params?: BasicObject;
  svgIcon?: ({active}: {active: boolean}) => React.ReactNode;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: homeIcon,
    activeImg: homeActiveIcon,
  },
  {
    name: 'Promotions',
    link: 'index/promotion',
    component: Promotion,
    img: promotionsIcon,
    activeImg: promotionsActiveIcon,
  },
  {
    name: 'Invite',
    link: 'index/referral',
    component: Invitation,
    img: inviteIcon,
    activeImg: inviteIcon,
  },
  {
    name: 'ProxyHome',
    link: 'index/proxy-home',
    component: ProxyHome,
    svgIcon: AgentIcon,
    params: {
      hideBack: true,
    },
  },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: meIcon,
    activeImg: meActiveIcon,
  },
];

const CusDefautTab = ({
  index,
  label,
  isFocused,
  options,
  onPress,
}: {
  index: number;
  label: string;
  isFocused: boolean;
  options: BottomTabNavigationOptions;
  onPress: (isFocused: boolean) => void;
}) => {
  return (
    <NativeTouchableOpacity
      activeOpacity={0.8}
      accessibilityRole="button"
      accessibilityState={isFocused ? {selected: true} : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      testID={options.tabBarTestID}
      onPress={() => onPress(isFocused)}
      style={[
        theme.flex.center,
        theme.flex.flex1,
        theme.padding.tbs,
        theme.position.rel,
        theme.overflow.visible,
      ]}>
      {label === 'Invite' ? (
        <>
          <Image
            style={[
              // eslint-disable-next-line react-native/no-inline-styles
              {
                height: 54,
                width: 54,
                borderRadius: 25,
                bottom: 20,
              },
              theme.position.abs,
            ]}
            source={mainPageList[index].activeImg!}
          />
          <View style={[theme.icon.l]} />
        </>
      ) : mainPageList[index].svgIcon ? (
        mainPageList[index].svgIcon!({active: isFocused})
      ) : (
        <Image
          style={theme.icon.l}
          source={
            isFocused
              ? mainPageList[index].activeImg!
              : mainPageList[index].img!
          }
        />
      )}

      <Text
        blod={isFocused}
        fontSize={10}
        style={{
          color: isFocused ? theme.basicColor.primary : theme.basicColor.dark,
        }}>
        {label}
      </Text>
    </NativeTouchableOpacity>
  );
};

const MainNav = () => {
  const {i18n} = useTranslation();

  return (
    <Tab.Navigator
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={props => {
        return (
          <Shadow {...shadow}>
            {props.state?.routes.map((route, index) => {
              const {options} = props.descriptors[route.key];
              const label =
                options.tabBarLabel !== undefined
                  ? (options.tabBarLabel as string)
                  : options.title !== undefined
                  ? options.title
                  : route.name;
              const onPress = (isFocused: boolean) => {
                if (isFocused) {
                  return;
                }
                if (route.name === 'ProxyHome') {
                  if (globalStore.userInfo?.isAgent !== 1) {
                    return toAgentApply();
                  }
                }
                props.navigation.navigate(route.name);
              };
              return (
                <CusDefautTab
                  key={label}
                  index={index}
                  label={label}
                  isFocused={props.state.index === index}
                  options={options}
                  onPress={onPress}
                />
              );
            })}
          </Shadow>
        );
      }}
      screenOptions={{
        headerShown: false,
      }}>
      {mainPageList.map(v => (
        <Tab.Screen
          key={v.name}
          name={v.name}
          component={v.component}
          options={{
            title: i18n.t(`home.tab.${v.name.toLocaleLowerCase()}`),
            unmountOnBlur: v.unmountOnBlur,
          }}
          initialParams={v.params}
        />
      ))}
    </Tab.Navigator>
  );
};

export default MainNav;
